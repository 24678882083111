import { formatYear } from '@/helpers/shared'

export default [
    {
        headerName: 'Title',
        field: 'title',
    },
    {
        headerName: 'File Name Mask',
        field: 'fileNameMask',
    },
    {
        headerName: 'Component Name',
        field: 'componentName',
        filter: 'agTextColumnFilter',
        filterParams: {
            caseSensitive: true,
        },
        floatingFilter: false,
        suppressMenu: true,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        valueFormatter: (params) => formatYear(params.value),
        filter: 'agTextColumnFilter',
        floatingFilter: false,
        suppressMenu: true,
    },
    { headerName: 'Active', field: 'isActive' },
    {
        cellRendererFramework: 'RowActionButtons',
        pinned: 'right',
        width: 100,
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'sis-imports-edit',
                    role: 'ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT',
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
            ],
            deleteButton: {
                actionParams: ['lea', 'id'],
                namespace: 'sisImports',
                vuexMethod: 'remove',
                title: 'Template',
                roles: 'ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE',
                itemIdentifier: 'fileNameMask',
                itemType: 'template',
            },
        },
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
