<template>
    <parent-layout title="SIS Imports" sub-heading>
        <template #actions>
            <v-btn
                :to="{ name: 'sis-imports-create' }"
                :disabled="!hasCreateRole()"
                class="primary ml-4"
                >Add Template
            </v-btn>
        </template>
        <ag-grid-server-side
            :ag-grid-filter-namespace="agGridFilterNameSpace"
            :ag-grid-filter-config="agGridFilterConfig"
            :server-side-datasource="serverSideDatasource"
            server-side-store-type="full"
            :column-defs="columnDefs"
            :default-page-size="25"
        >
        </ag-grid-server-side>
    </parent-layout>
</template>

<script>
import columnDefs from '@/helpers/ag-grid/columnDef/sisImportsOverview'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import DataSource from '@/helpers/ag-grid/datasource/graphql'
import { ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE } from '@/helpers/security/roles'
import { getYears, formatYear } from '@/helpers/shared'
import { currentSchoolYear } from '@/helpers/form/formatting'
import { mapActions, mapGetters } from 'vuex'
import ParentLayout from '@/components/layout/ParentLayout'

export default {
    components: {
        ParentLayout,
        AgGridServerSide,
        ParentLayout,
    },
    data: () => ({
        columnDefs: columnDefs,
        agGridFilterNameSpace: 'sisImportAgGrid',
    }),
    computed: {
        ...mapGetters({
            lea: 'getDistrictLea',
            items: 'sisImports/getItems',
            hasRole: 'auth/hasRole',
            components: 'components/getItems',
        }),
        agGridFilterConfig() {
            const startYear = currentSchoolYear()
            const years = getYears(startYear, 12).map((year) => ({
                itemText: formatYear(year),
                itemId: year,
            }))

            const componentItems = this.components
                .filter((c) => !c.hideFromDisplay)
                .sort((a, b) => a.displayOrder > b.displayOrder)
                .map((c) => c.componentName)

            return {
                definition: [
                    {
                        label: 'School Year',
                        filterField: 'schoolYear',
                        filterType: 'autocomplete',
                        width: '170px',
                        defaultValue: {
                            itemText: formatYear(startYear),
                            itemId: startYear,
                        },
                    },
                    {
                        label: 'Component Name',
                        filterField: 'componentName',
                        filterType: 'autocomplete',
                        width: '200px',
                    },
                ],
                data: {
                    schoolYear: years,
                    componentName: componentItems,
                },
            }
        },
    },
    mounted() {
        this.fetchComponents()
    },
    methods: {
        ...mapActions({
            fetchComponents: 'components/get',
            fetchTemplates: 'sisImports/get',
        }),
        serverSideDatasource: function () {
            const fetchRows = async () => {
                const params = {
                    lea: this.lea,
                }

                await this.$store.dispatch('sisImports/get', params)
            }

            return {
                getRows: DataSource.fullStore(fetchRows, 'sisImports/getItems').getRows,
            }
        },
        hasCreateRole: function () {
            return this.hasRole(ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE)
        },
    },
}
</script>
